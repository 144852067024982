@import '../../styles/variables.scss';

.form {
  box-sizing: border-box;
  
  &__fieldset {
    padding: 1rem 0;
    border: none;
    margin: 0;

    &__title {
      text-align: left;
      font-family: $font-family-base;
      font-weight: $font-family-semibold-weight;
      text-transform: uppercase;
      color: $color-accent;
      font-size: 1.8rem;
      line-height: 2.2rem;
      margin-bottom: 0.8rem;
    }

    &--hidden {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
    }

    &--highlight {
      border-radius: 1rem;
      border: 0.3rem solid $color-accent;
      padding: 2rem 2.5rem;
      margin: 2rem 0;
    }
  }

  &__submit {
    width: 100%;
    max-width: 36.4rem;
    margin-top: 2rem;

    &--hidden {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
    }
  }

  &__privacy {
    text-align: left;
    margin: 0;
    margin-top: 40px;
    line-height: 2rem;
    font-size: 14px;
    font-style: italic;
    
  .title {
      font-weight: bold;
      margin-bottom: 10px;
  }
    a {
      font-weight: 600;
      text-decoration: underline;
      &:hover
      {
        text-decoration: none;
      }
    }
  }

  &__intro {
    margin-bottom: 2rem;
    font-size: 1.6rem;
    line-height: 2rem;
    margin: 0.7rem auto 2rem;
    max-width: 42.6rem;
    display: block;
  }

  &__date-entry {
    padding-left: 4rem;

    .checkbox__label {
      padding: 0;
      margin-bottom: 1.5rem;
    }
  }


  &__disclaimer {
    max-width: 36.4rem;
    margin: 2rem auto;
    text-align: left;
    font-size: 1.4rem;
    line-height: 2.2rem;
  }


  &--slim {
    max-width: 42.6rem;
    display: block;
    margin: 0 auto;
  }
}

.g-recaptcha-v2 
{
  margin: 20px auto;
  display: inline-block;
}

@media screen and (min-width: $tab) {
  .form {
    &__intro {
      font-size: 1.8rem;
    }

    &__disclaimer {
      line-height: 2.2rem;
    }
  }
}
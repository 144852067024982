@import '../../styles/variables.scss';

.step {
  position: relative;
  width: 24rem;
  box-sizing: border-box;

  & ~ & {
    margin-top: 3rem;
  }

  &__num {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    border-radius: 50%;
    border: 3px solid $color-primary;
    background: $color-white;
    width: 5rem;
    height: 5rem;
    left: 0;
    top: 0;
  }

  &__title {
    margin: 1rem auto 1rem;
  }

  &__info {
    max-width: 20rem;
    margin: 0 auto;
  }

  &__number {
    position: relative;
    color: $color-primary;
    font-family: $font-family-bold;
    font-weight: $font-family-bold-weight;
    font-size: 3.4rem;
    line-height: 4.1rem;
    z-index: 2;
  }

  &__image {
    width: 100%;
    border-radius: $step-border-radius;
    border: $step-border;
    max-width: 24rem;
    box-sizing: border-box;
  }


  @media screen and (min-width: $tab) {
    
    & ~ & {
      margin-top: 0;
    }
  }

 
}
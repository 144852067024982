@import "../styles/variables.scss";

.grecaptcha-badge {
  z-index: 101;
  bottom: 14rem !important;
  @media screen and (min-width: $mobWide) {
    bottom: 7.5rem !important;
  }
}
/*
#onetrust-consent-sdk .onetrust-pc-dark-filter.ot-hide {
  display: block !important;
}*/
#onetrust-consent-sdk #onetrust-banner-sdk {
  display: flex;
  justify-content: center;
  z-index: 2147483646;
}

*,
::before,
::after {
    box-sizing: border-box;
}

html {
  height: 100%;
  color: $color-white;
  scroll-behavior: smooth;
}
body 
{
  height: 100%;
}
.switch-wrapper {
  position: relative;
  width: 100%;
}

.switch-wrapper > div {
  width: 100%;

  .routing & {
    position: absolute;
  }
}

main {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: calc(100vh - 14.9rem);

  @media screen and (min-width: $mobWide) {
    min-height: calc(100vh - 7.3rem);
  }
}

.layout-general {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 6.6rem;

  &--conffeti {
    .layout-container {
      position: relative;
      z-index: 1;
    }
  }

  &__confetti {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    animation: confetti 1.5s .05s forwards;
    opacity: 0;
    transform: scale(.5);
  }

  &--logo-pattern {
    background: url(../assets/images/campaign/logo-patten.png) repeat;
    background-size: cover;
    min-height: 100vh;

    @media screen and (min-width: $deskWide) {
      background-size: auto;
    }
  }

  @media screen and (min-width: $tab) {
    margin-top: 8.4rem;
  }
}

.layout-wrapper {
  display: flex;
  justify-content: center;
    &--drkBackground {
      background-color: $color-secondary;
    }

    &--column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
}

.layout-container {
  width: 100%;
  padding: 6rem 2.5rem;
  max-width: $mob;
  box-sizing: border-box;

  &--banner {
    padding: 2rem 2.5rem 4rem;
  }

  &--center {
    text-align: center;
  }

  &--bottom-space {
    padding-top: 0;
  }

  @media screen and (min-width: $tab) {
    max-width: 108rem;
    

    &--banner {
      padding: 3rem 2.5rem 5rem;
    }

    &--slim {
      max-width: 64.4rem;
    }

    &--wide {
      max-width: $deskWide;
    }

    &--medium {
      max-width: $tab;
    }

  }

  @media screen and (min-width: 819px) {
   
    &--medium {
      padding: 6rem 0rem;
    }
  }

  @media screen and (min-width: 1130px) {
    padding: 6rem 0rem;

    &--bottom-space {
      padding-top: 0;
    }
  }

}
@import '../../styles/variables.scss';

.banner {
  width: 100%;
  background-image: url("../../assets/images/campaign-2023/key\ visuals/kv-header-bg-mob-23.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;

  &__content-img {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    margin: auto;
  }
}

@media screen and (min-width: $mobWide) {
  .banner {
    background-image: url("../../assets/images/campaign-2023/key\ visuals/kv-header-bg-23.png");
  }
}

@import '../../styles/variables.scss';

.sumtsandcs {
    $this: &;
    text-align: left;
    margin: 0;
    margin-top: 0;
    font-size: 1.4rem;
    line-height: 1.7rem;

    &__title {
        font-weight: $font-family-bold-weight;
        margin-bottom: 1rem;

        span {
            display: block;
            font-weight: $font-family-base-weight;
        }
    }

    &__disclaimer {
        border: 1px solid $color-white;
        padding: 1rem;
        font-style: italic;
        margin-bottom: 2rem;
    }

    &__section {
        + #{$this}__section {
            margin-top: 2.5rem;
        }
    }

    a {
        font-weight: $font-family-bold-weight;
        text-decoration: underline;

        &:hover
        {
            text-decoration: none;
        }
    }

    @media screen and (min-width: $tab) {
        line-height: 2rem;
    }
}
@import '../../styles/variables.scss';

.textfield {
  $this: &;
  color: $color-black;

  &__label {
    display: block;
    color: $color-white;
    text-align: left;
    font-size: 1.8rem;
    line-height: 2.1rem;
    margin-bottom: .5rem;
  }

  &__label-info {
    display: block;
    font-size: 1.2rem;
    line-height: 1.6rem;
  }

  &__info {
    text-align: left;
  }

  &__input {
    width: 100%;
    font-size: 1.6rem;
    color: $color-black;
    background-color:  $color-white;
    border: $textfield-border;
    border-radius: $textfield-border-radius;
    padding: $textfield-padding;
    position: relative;
    z-index: 1;
    line-height: normal;

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: $color-black;
      -webkit-box-shadow: 0 0 0px 1000px $color-white inset;
    }

    &--select {
      width: 100%;
      background-color: $color-white;
      color: $color-black;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%233B0073" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"/></svg>');

      background-repeat: no-repeat;
      background-position-x: calc(100% - 0.5rem);
      background-position-y: 50%;
      border-radius:  $textfield-border-radius;
      padding: $textfield-padding;
      padding-right: 5rem;

      &:focus {
        outline: none;
      }
    }

    #{$this}--error & {
      border: $textfield-border-error;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 0.2rem $color-secondary, 0 0 0 0.4rem $color-accent;
    }

    &[disabled] {
      background: rgba($color-secondary, .5);
      color: $color-white;
    }
  }
}
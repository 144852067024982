@import '../../styles/variables.scss';

.link {
  $this: &;
  color: $color-white;
  transition: all .2s;

  &:hover, &:focus {
    color: $color-accent;
    outline: none;
  }

  &--lozenge {
    width: 100%;
    display: block;
    color: $color-white;
    font-family: $font-family-bold;
    font-weight: $font-family-bold-weight;
    font-size: 1.6rem;
    line-height: 1.8rem;
    letter-spacing: 0.08rem;
    text-transform: uppercase;
    border-radius: $button-border-radius;
    background: $button-background;
    border: $button-border;
    padding: $button-padding;
    text-decoration: none;
    text-align: center;
    box-sizing: border-box;

    &:hover,
    &:active {
      color: $color-white;
      background: $color-primary;
      border: $button-border-focus;
      outline: none;
    }

    &:focus {
      outline: none;
      color: $color-white;
      background: $button-background;
      box-shadow: 0 0 0px 2px $button-background, 0px 0px 0px 4px $color-accent;
    }
  }

  @media screen and (min-width: $tab) {
    &--lozenge {
      margin: 0 auto;
      max-width:  36.4rem;
    }
  }
}
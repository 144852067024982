@keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 0%;
	}
  100% {
		background-position: 0% 50%;
	}
}

@keyframes zoom {
	0% {
		opacity: 0;
		transform: scale(.6);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes confetti {
	0% {
		opacity: 0;
		transform: scale(.5);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes badgeFadeIn {
	0%{
	  opacity: 0;
	  transform: scale(.9);
	}
	5% {
	  opacity: 0;
	  transform: scale(1);
	}
	20% {
	  opacity: 1;
	  transform: scale(1);
	}
	80% {
	  opacity: 1;
	  transform: scale(1);
	}
	100% {
	  opacity: 0;
	  transform: scale(1.05);
	}
  }
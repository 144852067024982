@import '../../styles/variables.scss';

.thankyou {
  $this: &;
  display: flex;
  flex-direction: column;
  align-items: center;

  .type-h2 {
    line-height: 3.6rem;
    margin-bottom: 1.5rem;

    @media screen and (min-width: $tab) {
      margin-bottom: 2.5rem;
      max-width: 48rem;
    }
  }

  &__banner {
    border: $thanks-border;
    border-radius: $thanks-border-radius;
    width: calc(100% - .3rem);
    max-width: 45rem;
    margin: 2rem 0;
  }

  &__para {
    font-size: 2rem;
    line-height: 2.4rem;
    margin: 0 auto;
  }

  &__para + &__para {
    margin-top: 1.5rem;
  }

  &__signup {
    margin: 4.5rem 0;
    max-width: 100%;
  }

  &__img {
    margin: 6rem auto 1rem;
    max-width: 100%;
  }

  &__cta {
    margin: 3.5rem auto 3rem;
  }

  &__loader {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 999;
  }


  @media screen and (min-width: $tab) {
    .type-h2 {
      letter-spacing: 0.114rem;
    }

    &__banner {
      margin: 3rem 0;
    }

    &__para {
      font-size: 2.2rem;
      line-height: 3rem;
    }

    &__img {
      margin: 4rem auto 1rem;
    }

    &__cta + .type-disclaimer {
      margin-top: 1rem;
    }
  
  }
}


@keyframes step1_1 {
  0% {
    transform: translateY(75%) scale(0);
    opacity: 0;
  }
  100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }
}

@keyframes step1_2 {
  0% {
    transform: translateY(-75%) scale(0);
    opacity: 0;
  }
  100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0%{
    transform: scale(.9);
    opacity: 0;
  }
  100%{
    transform: scale(1);
    opacity: 1;
  }
}
@import '../../styles/variables.scss';

.tandc {
  font-weight: $font-family-medium-weight;

  .button--text-link {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  &__disclaimer {
    border: 0.1rem solid $color-white;
    padding: 1rem;
    text-align: center;
    font-weight: $font-family-semibold-weight;
    margin-top: 2rem;
  }

  &__heading {
    background-color: $color-secondary;
    color: $color-accent;
    text-transform: uppercase;
  }

  &__links {
    
    ul {
      li + li {
        margin-top: 1rem;
      }
    }

    a {
      display: block;
      font-size: 1.4rem;
      font-weight: $font-family-semibold-weight;
    }
  }

  table {
    margin: 3rem auto;
    border: 0.1rem solid $color-white;
    border-collapse: collapse;

    tr th {
      text-transform: uppercase;
      font-size: 1.6rem;
    }

    &:first-of-type tr {
      border-bottom: 1px solid $color-primary;
    }
  }

  th {
    background-color: $color-white;
    color: $color-primary;
    padding: 1rem;
  }
  
  td {
    padding: 0.5rem;
    border: 1px solid $color-white;
    vertical-align: top;
    width: 50%;
  }
}

@media screen and (min-width: $tab) {
  .tandc {
    .button--text-link {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
    
    &__links {
      a {
        font-size: 1.8rem;
      }
    }
  }
}
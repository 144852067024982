@import "styles/variables.scss";


//ipad pro only
@media screen and (min-width: 1023px) and (max-width: 1025px) and (min-height: 1365px) and (max-height: 1367px) 
{
  .prize-select-page
  {
    height: auto;
    overflow: hidden;
  }

  .switch-wrapper .actionbar--show
  {
      transform: translateY(-200px);
  }
}


.prize-select {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 10rem;

  .type-h2 {
    margin-bottom: 0.5rem;
  }

	&__list {
	  margin-top: 3rem;
    display: flex;
    flex-flow: wrap;
		justify-content: space-between;
		
		.prize {
			width: calc(50% - 1rem);
			margin-bottom: 2rem;
    }
    
    @media screen and (min-width: $tab) {
      margin-top: 6rem;

      .prize {
        width: calc(100% / 3);
        margin-bottom: 3rem;
      }
    }

    @media screen and (min-width: $tabDesk) {
      .prize {
        width: 25%;
      }
    }
	}

  @media screen and (min-width: $tab) {
    p {
      max-width: 62.4rem;
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }
}
@import '../../styles/variables.scss';

.button {
  transition: all .2s;
  display: inline-block;
  color: $color-white;
  font-family: $font-family-bold;
  font-weight: $font-family-bold-weight;
  font-size: 1.6rem;
  line-height: 1.8rem;
  letter-spacing: 0.08rem;
  text-transform: uppercase;
  border-radius: $button-border-radius;
  background: $button-background;
  border: $button-border;
  padding: $button-padding;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  $this: &;
  box-sizing: border-box;

  &:hover,
  &:active {
    color: $color-white;
    background: $color-primary;
    border: $button-border-focus;
    outline: none;

    #{$this}__plus-icon,
    #{$this}__minus-icon  {
      border-color: $color-accent;
      &::before, &::after {
        background-color: $color-accent;
      }
    }
  }

  &:focus {
    outline: none;
    color: $color-white;
    background: $button-background;
    box-shadow: 0 0 0px 2px $button-background, 0px 0px 0px 4px $color-accent;
  }

  &--icon {
    display: flex;
    align-items: center;
  }

  &__plus-icon {
    border: 0.1rem solid $color-white;
    background-color: $color-secondary;
    display: inline-block;
    position: relative;
    width: 3.2rem;
    height: 3.2rem;
    margin-left: 1.5rem;
    transition: all .2s;

    &::before, &::after {
      content: "";
      display: block;
      width: 1.7rem;
      height: 0.3rem;
      background-color: $color-white;
      border-radius: 1rem;
      position: absolute;
      transition: all .2s;
    }

    &::before {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &::after {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }


  &__minus-icon {
    border: 0.1rem solid $color-white;
    background-color: $color-secondary;
    display: inline-block;
    position: relative;
    width: 3.2rem;
    height: 3.2rem;
    margin-right: 1.5rem;
    transition: all .2s;

    &::before {
      content: "";
      display: block;
      width: 1.7rem;
      height: 0.3rem;
      background-color: $color-white;
      border-radius: 1rem;
      position: absolute;
      transition: all .2s;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    
  }

  &--wide {
    width: 100%;

    @media screen and (min-width: $tab) {
      max-width: 36.4rem;
    }
  }

  &--full {
    width: 100%;
  }

}

.button--text-link {
  padding: 0;
  letter-spacing: 0;
  text-decoration: none;
  text-transform: none;
  min-width: unset;
  width: auto;
  
  font-size: 1.6rem;
  line-height: 2rem;
  border: 0;
  background: transparent;
  cursor: pointer;
  text-decoration: underline;

  &:focus {
    border: 0;
  }

  &:hover {
    background: transparent;
    color: $color-accent;
    border: 0;
  }
}
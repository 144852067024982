// Generic variables
$mob: 420px;
$mobWide: 640px;
$tab: 769px;
$tabDesk: 992px;
$deskWide: 1300px;

$font-family-base: gill-sans-nova, sans-serif;
$font-family-base-weight: 400;
$font-family-medium: $font-family-base;
$font-family-medium-weight: 500;
$font-family-semibold: $font-family-base;
$font-family-semibold-weight: 600;
$font-family-bold: $font-family-base;
$font-family-bold-weight: 700;


$timers: (
  'primary': (
    'foreground': #ffc900,
    'background': #1f1542,
    'color': #fff,
  ),
  'secondary': (
    'foreground': #25b24b,
    'background': #1f1542,
    'color': #fff,
  ),
  'tertiary': (
    'foreground': #1aabe3,
    'background': #1f1542,
    'color': #fff,
  ),
  'quaternary': (
    'foreground': #ed1064,
    'background': #1f1542,
    'color': #fff,
  ),
);

// Theme variables
@import "./themes/cadburyfc/variables.scss";
@import "../../styles/variables.scss";

.checkbox {
  $this: &;
  position: relative;
  text-align: left;
  padding: 2rem 0 0;

  &:first-of-type {
    padding: 0;
  }

  &__input {
    position: absolute;
    left: 0;
    padding: 1rem;
    margin: 0;
    opacity: 0;
    z-index: 2;
    width: 3rem;
    height: 3rem;
  }

  &__label {
    display: inline-block;
    padding-left: 4.1rem;
    padding-top: 0rem;
    text-align: left;
    min-height: 3rem;
    font-size: 1.6rem;
    line-height: 1.7rem;

    a {
      font-weight: $font-family-semibold-weight;
    }
  }

  &__fake {
    position: absolute;
    left: 0;
    width: 2.4rem;
    height: 2.4rem;
    display: block;
    background: $color-white;

    &:after, &:before {
      content: '';
      transform: translate(-50%, -50%) rotate(45deg);
      position: absolute;
      top: 1.3rem;
      left: 1.5rem;
      width: 0.5rem;
      height: 2rem;
      background: transparent;
      border-radius: 0.2rem;
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
      width: 0.5rem;
      height: 1rem;
      left: 0.7rem;
      top: 1.7rem;
    }
  }

 

  &__focus {
    background: transparent;
    display: block;
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    border: 0.2rem solid transparent;
  }

  &__input:focus + &__fake {
    opacity: 1;

    #{$this}__focus {
      border-color: $color-accent;
    }

    #{$this}--error & {
      border: $textfield-border-error;
    }

  }

  &__input:checked + &__fake {
    opacity: 1;

    &:after, &:before {
      background: #008A22;
    }
  }

  &--alt-color {
    #{$this}__fake {
      border-color: $color-accent;
    }

    #{$this}__input:checked + #{$this}__fake {
      border-color: $color-accent;
    }
  }

  &--error {
    #{$this}__fake {
      border-color: $color-alert;
      opacity: 1;
    }

    #{$this}__focus {
      border-color:transparent;
    }
  }
}

@media screen and (min-width: $tab) {
  .checkbox {
    &__label {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }
}

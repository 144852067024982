@import 'styles/variables.scss';

.keyvis {
  position: relative;
  margin-top: 4.3rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 10;
  background: #1D1F20;
  position: relative;
  overflow: hidden;
  min-height: 45rem;

  &--mopup {
    align-items: initial;
  }
  
  &__bg {
    transition: all .0s;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../assets/images/campaign/kv-header-bg-mob.jpg);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
  }

  &__content-img {
    position: relative;
    display: block;
    max-width: 100%;
  }

  &__content-logo {
    position: absolute;
    display: block;
    right: 2rem;
    bottom: 1rem;
    width: 4rem;
    height: 4rem;
  }

  @media screen and (min-width: 640px) {
    min-height: 40rem;
    align-items: center;

    &__bg {
      background-image: url(../../assets/images/campaign/kv-header-bg.jpg);
      background-position: center 80%;
    }
  }

  @media screen and (min-width: $tab) {
    margin-top: 7.6rem;
  }

  @media screen and (min-width: $tabDesk) {
    min-height: 54rem;
  }

  @media screen and (min-width: $deskWide) {
    min-height: 58rem;
  }
}

.react-parallax  {
  position: absolute!important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
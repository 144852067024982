@import '../../styles/variables.scss';

.attraction-list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2rem;
  row-gap: 2rem;
  justify-content: center;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-top: 2.6rem;
  margin-bottom: 4rem;

  &__item {
    grid-column: span 1 / span 1;

    &:nth-child(3n+1) {
      &:last-child {
        grid-column: span 2 / span 2;  
      }
    }

    .modal {
        .modal__icon {
            width: 8rem;
            height: 8rem;
            margin-left: auto;
            margin-right: auto;
        }

        p {
            color: $color-white;
        }
    }
  }

  &__text {
    margin-top: 8px;
    font-family: $font-family-bold;
    font-weight: $font-family-base-weight;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  @media screen and (min-width: $tab) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 3rem;
    row-gap: 3rem;
    margin-top: 4.5rem;

    &__item {
      &:nth-child(3n+1) {
        &:last-child {
          grid-column: span 3 / span 3; 
        }
      }
    }
  }
}

.attraction-block {
  display: block;
  background: transparent;
  padding: 0;
  border: 0;
  cursor: pointer;
  max-width: 170px;
  margin: 0 auto;
  //border: 0;

  img {
      display: block;
      width: 100%;
      height: 100%;
  }

  span {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
  }

  @media screen and (min-width: $tab) {
    max-width: 220px;
  }
}
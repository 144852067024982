@import '../../styles/variables.scss';

.visually-hidden {
  display: none;
}

.home {
  &__banner {
    background-color: transparent;
    flex-direction: column;
    overflow: hidden;

    &-container {
      height: 100%;
    }
  }

  &__entrybar {
    position: relative;
    padding: 2.5rem 2.5rem 6rem;
    text-align: center;

    &-confetti {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      animation: confetti 1.5s .05s forwards;
      opacity: 0;
      transform: scale(.5);
    }

    &--secondary {
      padding: 0 2.5rem;
    }

    @media screen and (min-width: $tab) {
      padding: 0 2.5rem 5.5rem;

      &--secondary {
        padding: 0 2.5rem;
        
        .home__intro {
          max-width: 64.8rem;
        }
      }
    }
  }

  &__intro {
    position: relative;
    z-index: 1;
    display: block;
    max-width: 62rem;
    margin: 0 auto;
    box-sizing: border-box;

    h1 {
      visibility: hidden;
      position: absolute;
      height: 0;
    }
    
  }

  &__intro-strapline, 
  &__intro-body {
    font-size: 2rem;
    line-height: 2.4rem;
    font-family: $font-family-base;

    @media screen and (min-width: $tab) {
      font-size: 2.2rem;
      line-height: 3rem;
    }
  }

  &__disclaimer {
    position: relative;
    z-index: 1;
    display: block;
    margin: 0 auto;
    box-sizing: border-box;
    margin-top: 3.8rem;

    @media screen and (min-width: $tab) {
      margin-top: 4rem;
    }
    
    p {
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: $font-family-base-weight;

      a {
        font-weight: $font-family-semibold-weight;
        &:hover {
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
  }

  &__intro-strapline {
    font-weight: $font-family-semibold-weight;

    &--uppercase {
      text-transform: uppercase;
    }
  }

  &__intro-body {
    margin-top: 2.5rem;

    .link {
      font-weight: 600;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__cta {
    position: relative;
    z-index: 1;
    width: 100%;
    text-align: center;
    margin-top: 3.4rem;

    @media screen and (min-width: $tab) {
      margin-top: 4.2rem;

      &--secondary {
        margin-top: 5.8rem;
      }
    }
    
  }

  &__prizes {
    overflow: hidden;
    background: $color-secondary;
  }

  &__carousel {
    //display: flex;
    //justify-content: center;

    @media screen and (min-width: $tab) {
      &.hide-pagination-desktop {
        .swiper-pagination {
          display: none;
        }
      }
    }
  }

  &__prize-list {
    margin: 6.4rem auto 0;
    //max-width: 100rem;

    @media screen and (min-width: $tab) {
      margin: 5.5rem auto 0;
    }
  }

  &__prize-block-list {
    margin-top: 5rem;

    &-cols {
      margin-top: 3rem;
      
      .prize-block + .prize-block {
        margin-top: 3rem;
      }
    }

    .prize-block--horizontal + .prize-block--horizontal {
      margin-top: 6rem;
    }

    @media screen and (min-width: $tab) {
      &-cols {
        display: flex;
        margin-top: 3rem;

        .prize-block {
          width: 50%;
        }
      }

      .prize-block:not(.prize-block--horizontal) + .prize-block:not(.prize-block--horizontal) {
        margin-left: 3rem;
        margin-top: 0;
      }

      .prize-block--horizontal + .prize-block--horizontal {
        margin-top: 4rem;
      }
    }
  }

  &__prize-blocks {
    overflow: hidden;
    background: $color-primary;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 3rem #00000080;

    &-title {
      max-width: 50rem;
      padding: 1rem 2rem 0;
      margin: 0 auto;
      font-size: 1.6rem;
      //font-weight: $font-family-semibold-weight;
      line-height: 2.1rem;
      width: calc(100% - 4rem);
    }

    @media screen and (min-width: $mob) {
      &-title {
        font-size: 1.8rem;
      }
    }
  }
  
  &__steps {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin: 5rem auto 0;
    align-items: center;
  
    @media screen and (min-width: $tab) {
      flex-direction: row;
      justify-content: center;
      margin: 2.6rem auto 0;
      align-items: flex-start;

      .step {
        width: calc(100% / 4 - 5px);
        margin-right: 4.2rem;
        max-width: 23.8rem;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  &__codeentry {
    margin: 2rem auto 0;
    max-width: 64rem;

    &-para {
      margin-bottom: 2rem;
    }

    &-reminder {
      padding-top: 1.5rem;
      display: inline-block;

      @media screen and (min-width: $mob) {
        padding-top: 3rem;
      }
    }
  }

  &__holding {
    padding: 6rem 3rem 3rem;
    max-width: 140rem;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &-wrap {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      margin-top: -2rem;
    }

    &-kv {
      align-self: center;
      width: 100%;
    }

    &-prizes {
      display: flex;
      justify-content: space-between;

      &--set1 {
        margin-top: 2rem;
      }

      img {
        width: calc(50% - 1rem);
      }
    }

    &-copy {
      padding: 2rem;
      text-align: center;
    }

    &-cta {
      padding-top: 3rem;
      width: 100%;
      display: flex;
      justify-content: center;

      a {
        width: 100%;
      }
    }

    @media screen and (min-width: $tab) {
      &-wrap {
        padding-top: 6%;
        padding-bottom: 8%;
        margin-top: 5rem;
      }

      &-kv, &-copy {
        width: calc(55% - 6rem);
      }

      &-prizes {
        &--set1, &--set2 {
          position: absolute;
          left: 0;
          top: 0;
          flex-direction: column;
          width: 22.5%;
          margin-top: 0;

          img {
            width: 100%;
          }

          img + img {
            margin-top: 5rem;
          }
        }

        &--set2 {
          left: initial;
          right: 0;
        }
      }

      &-copy {
        max-width: 38rem;
      }
    }
  }
}
@import '../../styles/variables.scss';

.policies {
  text-align: left;
  margin-bottom: 4rem;
  word-break: break-word;
  font-size: 1.4rem;
  line-height: 1.7rem;
  margin-top: 2rem;

  .type-h3 {
    margin: 2rem 0 1rem;
  }

  p, a, li {
    word-break: break-word;
  }

  a {
    cursor: pointer;
  }

  h2 {
    font-size: 2rem;
    margin-top: 3rem;
  }

  h3 {
    font-size: 1.6rem;
    margin: 0 0 1rem;
  }

  p + p {
    margin-top: 2rem;
  }

  ul + h3, p + h3 {
    margin-top: 2rem;
  }

  ul, ol {
    margin: 1rem 0;
    padding: 1rem;

    li + li {
      margin-top: 2rem;
    }
  }


  ol li > ol {
    margin-left: 2rem;
  }

  ol li > ol li > ol {
    margin-left: 2rem;
  }

  ol li > ol li > ol li > ol {
    margin-left: 2rem;
  }

  ol + .type-h3 {
    margin-top: 5rem;
  }

  table {
    width: 100%;
    tr {
      td {
        padding: 1rem; 

        ul {
          padding-left: 2rem;
          margin: 0;

          li + li {
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
  

  &--alphalist {
    list-style-type: lower-alpha;
  }

  @media screen and (min-width: $tab) {
    font-size: 1.8rem;
    line-height: 2.2rem;

    ol + .type-h3 {
      margin-top: 7rem;
    }
  }
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy 
#cookie-policy-title, 
#cookie-policy-description, 
.ot-sdk-cookie-policy-group, 
.ot-sdk-cookie-policy-group-desc, 
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy span,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a {
  color: $color-white!important;
  background: transparent!important;
}

#onetrust-banner-sdk .ot-sdk-container, #onetrust-pc-sdk .ot-sdk-container, #ot-sdk-cookie-policy .ot-sdk-container {
  margin: 0!important;
  padding: 0!important;
}

#ot-sdk-btn.ot-sdk-show-settings, #ot-sdk-btn.optanon-show-settings {
  background: transparent!important;
  border: 0!important;
  padding: 0!important;
  font-size: initial!important;
  color: $color-secondary!important;
  font-weight: bold;

  &:hover {
    color: $color-primary!important;
  }
}

button#ot-sdk-btn.ot-sdk-show-settings {
  padding: 2rem 0!important;
  display: inline-block;
  color: $color-white!important;
  cursor: pointer;

  &:hover {
    color: $color-white!important;
    text-decoration: underline;
  }
}

#ot-sdk-cookie-policy {
  margin-top: 2rem;
}

.ot-sdk-cookie-policy-title {
  font-size: 2rem!important;
}

.ot-sdk-cookie-policy-group {
  font-size: 1.8rem!important;
}

#cookie-policy-description, .ot-sdk-cookie-policy-group-desc, .ot-cookies-td-content a, .ot-cookies-type-td-content, .ot-table-header {
  font-size: 1.6rem!important;
  line-height: 2.1rem!important;
}

button#ot-sdk-btn2.ot-sdk-show-settings {
  background: transparent!important;
  border: 0!important;
  padding: 0!important;
  font-size: initial!important;
  color: $color-secondary!important;
  font-weight: bold;
  
  display: inline-block;
  color: $color-white!important;
  cursor: pointer;

  &:hover {
    color: $color-white!important;
    text-decoration: underline;
  }
}

button.pseudolink {
  background: transparent!important;
  border: 0!important;
  padding: 0!important;
  font-size: initial!important;
  color: $color-secondary!important;
  font-weight: bold;
  
  display: inline-block;
  color: $color-white!important;
  cursor: pointer;

  &:hover {
    color: $color-white!important;
    text-decoration: underline;
  }
}
html {
  font-size: 62.5%;
  font-family: $font-family-base;
  font-weight: $font-family-base-weight;
}

body {
  font-size: 1.6rem;
  line-height: 2.1rem;
}

.text-center {
  text-align: center;
}

.type-h1 {
  font-family: $font-family-base;
  font-weight: $font-family-bold-weight;
  margin: 0 0 2rem;
  padding: 0;
  color: $color-white;
  text-transform: uppercase;
  font-size: 3.4rem;
  letter-spacing: .3rem;
  line-height: 3.4rem;

  &--gold {
    color: $color-accent;
    background: linear-gradient(90deg, rgb(3, 3, 3) 0%, rgba(209,168,83,1) 33%, rgba(254,235,176,1) 66%, rgba(241,205,118,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media screen and (min-width: $tab) {
    font-size: 5.5rem;
    line-height: 5.2rem;
    letter-spacing: .5rem;
    margin: 0 0 2rem;
  }
}

.type-h2 {
  font-family: $font-family-base;
  font-weight: $font-family-semibold-weight;
  font-size: 3.2rem;
  line-height: 3.4rem;
  letter-spacing: 0.096rem;
  margin: 0;
  color: $color-white;
  text-transform: uppercase;

  &--gold {
    color: $color-accent;
  }

  @media screen and (min-width: $tab) {
    font-size: 3.8rem;
    line-height: 3.6rem;
    letter-spacing: 0.114rem;
  }
}

.type-h3 {
  font-family: $font-family-bold;
  font-weight: $font-family-semibold-weight;
  font-size: 1.8rem;
  line-height: 1.8rem;
  letter-spacing: 0.054rem;
  margin-top: 0;
  color: $color-white;
  text-transform: uppercase;

  &--gold {
    color: $color-accent;
  }

  @media screen and (min-width: $tab) {
    font-size: 2.3rem;
    line-height: 2.5rem;
    letter-spacing: 0.069rem;
  }
}

.lowercase {
  text-transform: lowercase;
}

.type-hpara {
  font-family: $font-family-bold;
  font-weight: $font-family-semibold-weight;
  font-size: 1.8rem;
  line-height: 2.2rem;
  padding: 0;
}

.type-disclaimer {
  font-family: $font-family-base;
  font-weight: $font-family-base-weight;
  font-size: 1.4rem;
  font-weight: 2rem;
}

strong {
  font-family: $font-family-bold;
}

a {
  color: $color-white;

  &:hover, &:focus {
    color: $color-accent;
  }

  &:focus-visible {
    outline: none;
    //box-shadow: 0 0 0px 1px $color-accent;
  }
}

p {
  margin: 0;

  a {
    font-weight: $font-family-semibold-weight;
  }
}

p + p {
  margin-top: 1rem;
}
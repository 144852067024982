@import '../../styles/variables.scss';

.modal {
  width: 100%;
  max-width: 60rem;
  padding: 3rem 4rem 6rem;
  margin: 1.5rem;
  transition: all .6s;
  text-align: center;
  transform: scale(.9);
  opacity: 0;
  box-sizing: border-box;

  &--show {
    transform: scale(1);
    opacity: 1;
    &:focus-within, &:focus
    {
      outline: 0;
      border: 0;
    }
  }

  &--panel {
    background: $color-primary;
    border: 0;
    padding: 5.5rem 3.5rem 7rem;
    border-radius: 1rem;

    @media screen and (min-width: $tab) {
      padding: 4.5rem 12rem 7rem;
    }

    &--bordered {
      border: .3rem solid $color-white;
    }
  }

  &--country {
    border-radius: 0;
    padding: 6rem 2rem;
  }

  &__icon {
    margin-bottom: 2rem;
    max-width: 16rem;

    &--rounded {
      border-radius: 50%;
    }

    &--bordered {
      border: .3rem solid $color-white;
    }
  }

  &__title {
    text-transform: uppercase;
    font-family: $font-family-bold;
    font-weight: $font-family-semibold-weight;
    font-size: 2.1rem;
    line-height: 2.4rem;
    letter-spacing: 0.063rem;
    color: $color-accent;
    margin-bottom: 1.2rem;
  }

  p {
    background: none;
    border: none;
    padding: 0;
    width: 100%;
  }

  a ~ a, button ~ a {
    margin-top: 1rem;
  }

  .button, .link--lozenge {
    display: block;
    margin: 2.5rem auto 0;
    width: 100%;

    + .button, + .link--lozenge {
      margin-top: 2rem;
    }
  }

  &__close {
    text-indent: -200px;
    overflow: hidden;
    position: absolute;
    top: 1rem;
    right: 1rem;
    border-radius: 50%;
    background: $color-white;
    border: none;
    width: 5.3rem;
    height: 5.3rem;
    cursor: pointer;

    &:after, &:before {
      content: '';
      position: absolute;
      width: 0.5rem;
      height: 3rem;
      left: 50%;
      top: 50%;
      border-radius: 1rem;
      background: $color-primary;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:focus,
    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0px 2px $color-primary, 0px 0px 0px 4px $color-accent;
    }
  }

  &--panel-content {
    padding: 0;
    background: $color-primary;
    border: 0;
    border-radius: 1rem;
    max-height: 90vh;

    .modal__content {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      max-height: 90vh;
      padding: 5.5rem 3.5rem 7rem;
    }

    @media screen and (min-width: $tab) {
      padding: 0;

      .modal__content {
        padding: 4.5rem 12rem 7rem;
      }
    }

  }
  @media screen and (min-width: $tab) {
    &__close {
      transform: translate(50%, -50%);
      top: 0rem;
      right: 0rem;
    }

    &__icon {
      max-width: 23.4rem;
    }

    &__title {
      font-size: 2.8rem;
      line-height: 2.8rem;
      letter-spacing: 0.084rem;
      margin-bottom: 1rem;
    }

    &--panel--slim {
      padding: 3rem 11.8rem 2.5rem;
    }

    &--country {
      padding: 6rem;
    }
  
  }

  @media screen and (min-width: $tabDesk) {
    &--country {
      padding: 6rem 12rem;
    }
  
  }
}

.modal button + a 
{
  margin-top: 20px;
  display: block;
}
@import '../../styles/variables.scss';

.accordion {
  $this: &;
  overflow: hidden;
  border: .3rem solid $color-white;
  background: transparent;
  width: 100%;
  box-sizing: border-box;

  &__toggle {
    position: relative;
    font-family: $font-family-bold;
    font-weight: $font-family-semibold-weight;
    background: $color-white;
    text-align: left;
    width: 100%;
    border: none;
    padding: 2.2rem 5rem 2.2rem 2.5rem;
    color: $color-secondary;
    outline: none;
    font-size: 1.4rem;
    line-height: 1.6rem;
  }

  &__icon {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 2rem;
    width: 2rem;
    height: 2rem;
    margin-top: -.2rem;

    span {
      position: absolute;
      border-radius: .3rem;
      display: block;
      height: .4rem;
      width: 2.5rem;
      background: $color-secondary;
      top: 50%;

      &:nth-of-type(1) {
        transition: transform .3s;
        transform: rotate(90deg);
      }
    }
  }

  &__body {
    text-align: left;
    display: none;
    padding: 2rem;
    font-size: 1.4rem;

    p {
      margin: 0;

      &.disclaimer {
        margin-top: 3rem;
        font-style: italic;
      }
    }

    ul.secondary {
      li + li {
        margin-top: 1.5rem;
      }
    }

    p ~ p {
      margin-top: 1rem;
    }
  }

  &--open {
    border-color:  $color-accent;
    border-bottom-left-radius: 0.7rem;
    border-bottom-right-radius: 0.7rem;

    #{$this}__body {
      display: block;
    }

    #{$this}__icon {
      span:nth-of-type(1) {
        transform: rotate(0deg);
        opacity: 0;
        transition: transform .3s, opacity .3s;
      }
    }
  }

  &:focus-within {
    border-color: $color-accent;
    outline: none;
  }

  @media screen and (min-width: $mob) {
    margin-left: 0;

    button:focus {
      outline: none;
      border-color: $color-accent;
    }
    
    &__toggle {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }

    &__body {
      font-size: 1.6rem;
      line-height: 2.2rem;

      ul.secondary {
        li + li {
          margin-top: 2rem;
        }
      }

      p ~ p {
        margin-top: 1.5rem;
      }
    }
  }

}


@import '../../styles/variables.scss';

.actionbar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8rem;
  padding: 0 2rem;
  pointer-events: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 11;
  text-align: center;
  background: linear-gradient(180deg, transparent 0%, #20123D 55%);
  transform: translateY(100%);
  transition: transform .3s, opacity .3s;
  opacity: 0;
  pointer-events: none;
  box-sizing: border-box;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 10rem;
    background: #20123D;
  }

  &--show {
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
  }

  * {
    pointer-events: all;
  }
}
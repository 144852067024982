.modal-background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
  overflow-y: auto;
  background: rgba(0,0,0,.75);
  opacity: 0;
  transition: opacity .3s;

  &--show {
    opacity: 1;
    z-index: 9999999999;
  }

}
@import '../../styles/variables.scss';

.footer {
  $this: &;
  color: $color-white;
  display: block;
  position: relative;
  //margin-top: 5rem;
  z-index: 100;

  &__terms {
    display: none;
    position: absolute;
    top: 1.5rem;
    left: .5rem;
    color: #000;

    @media screen and (min-width: $mobWide) {
      left: 2rem;
    }

    a {
      text-decoration: underline;
    }
  }

  #{&} &__logo {
    display: none;
    margin: 0;
    position: absolute;
    bottom: 1.35rem;
    left: 50%;
    transform: translate3d(-50%, 0, 0);

    @media screen and (min-width: $mobWide) {
      display: inline-block;
    }

    > svg {
      height: 4rem;
      width: 16rem;
      fill: $color-secondary;
    }
  }

  #{&} &__menu {
    background: $color-white;
    display: flex;
    padding: 0 1.5rem;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    @media screen and (min-width: $mobWide) {
      text-align: left;
      order: 1;
      flex-direction: row;
      justify-content: flex-start;
      padding: 0 5rem;
    }

    &__links {
      order: 2;
      width: 100%;
      flex:1;
      margin: 0 0 1rem;

      @media screen and (min-width: $mobWide) {
        margin: 1rem 0 0;
      }
      
      a {
        color: $color-secondary;
        will-change: color;
        transition: 200ms ease color;
        font-size: 1.3rem;
        line-height: 1.7rem;
        font-family: $font-family-bold;
        font-weight: $font-family-semibold-weight;
        border: 0.2rem solid transparent;
        outline: none;

        &:hover {
          @media screen and (min-width: $mobWide) {
            border-bottom-color: $color-primary ;
          }
        }

        &:focus {
          border-color: $color-primary;
        }

        @media screen and (min-width: $mobWide) {
          font-size: 1.4rem;
          line-height: 1.4rem;
        }

        + a {
          margin-left: 1rem;
        }
      }
    }

    &__social-links {
    
      font-size: 1rem;
      margin: 1rem 0 0;
      display: flex;
      order: 1;
      padding: 0 1.5rem;
      padding-right: 2rem;

      @media screen and (min-width: $mobWide) {
        order: 2;
        margin: 0 0 0 auto;
      }

      a {
        width: 4rem;
        height: 4rem;
        padding: 1rem;
        display: block;
        margin-top: 0;
        outline: none;

        svg {
          transition: all 200ms ease;
          display: block;
          width: 2.4rem;
          height: 2.4rem;
          border: 0.2rem solid transparent;
        }

        &:hover svg {
          fill: $color-primary;
          width: 2.1rem;
          height: 2.1rem;
        }

        &:focus svg {
          border-color: $color-primary;
        }
      }

    }
  }

  #{&} &__copyright {
    font-size: 1.2rem;
    line-height: 1.2rem;
    display: flex;
    flex-direction: column;
    padding: 0 1.5rem 1.5rem;
    text-align: center;
    align-items: center;
    justify-content: center;
    background: $color-white;
    color: $color-secondary;

    @media screen and (min-width: $mobWide) {
      align-items: center;
      padding: 0 5rem 1rem;
      flex-direction: row;
      text-align: left;
    }

    &__text {
      margin-top: 0.5em;
      order: 2;
      flex: 1;
      font-size: 1.2rem;
      line-height: 1.2rem;

      @media screen and (min-width: $mobWide) {
        order: 1;
        margin-top: 0;
      }
    }

    &__logo {
      margin-top: 0;
      order: 1;
      float: right; //IE 10 fix

      @media screen and (min-width: $mobWide) {
        order: 2;
        margin: 0 0 0 auto;
      }

      > svg {
        $width: 11.5rem;
        $height: 2.3rem;

        width: $width;
        height: $height;
        display: block;
        fill: $color-secondary;
      }
    }
  }

  a {
    font-size: 0.9em;
    color: currentColor;
    text-decoration: none;
  }

  svg {
    display: inline-block;
    vertical-align: middle;
    fill: $color-secondary;
  }
}
  
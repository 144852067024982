@import '../../styles/variables.scss';

.attraction-block-modal {
  &--show {
    .modal-background {
      opacity: 1;
      z-index: 9999999999;
    }
  }
}


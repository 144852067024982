@import '../../styles/variables.scss';

.prize {
  $prize: &;
  position: relative;
  overflow: hidden;
  background: $color-primary;
  //height: calc(100% - 4px);
  text-align: center;

  &--selectable {
    background: transparent;
  }


  &__border {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - .6rem);
    height: calc(100% - .6rem);
    border: .3rem solid $color-accent;
    border-radius: 1rem;
    pointer-events: none;
    z-index: 2;

    #{$prize}--selectable & {
      border: .3rem solid transparent;
    }
  }

  &__radio {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
  }

  &__radio:checked + &__border {
    border-color: $color-accent;
  }

  &__tick {
    display: none;
  }

  &__radio:checked + &__border + &__image {

    #{$prize}__tick {
      display: block;
      position: absolute;
      border-bottom: 4px solid #008A22;
      width: 10px;
      border-right: 4px solid #008A22;
      height: 21px;
      transform: rotate(35deg);
      left: 1.3rem;
      top: .5rem;
    }
  }

  &__fake-radio {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background: $color-white;
    border-radius: 1rem;
    width: 4rem;
    height: 4rem;
  }

  &__title {
    padding: 2rem 1rem 0;
    margin: 0;
    display: block;

    #{$prize}--selectable & {
      padding: 1.2rem 1rem;
    }

    @media screen and (min-width: $tab) {
      padding: 2rem 2rem 0;

      #{$prize}--selectable & {
        padding: 1.2rem 2rem;
      }
    }
  }

  &__info {
    padding: 1rem 1rem 2rem;

    p {
      font-size: 1.6rem;
    }

    ol {
      margin: 0;
      padding: 1rem 2rem;
      list-style: disc;
    }

    @media screen and (min-width: $tab) {
      padding: 1rem 2rem 2rem;
    }
  }


  &__image {
    position: relative;

    img {
      width: auto;
      height: 100%;
      object-fit: contain;
      object-position: center;
      display: flex;
      margin: 0 auto;
      max-width: 100%;

      @media screen and (min-width: $tab) {
        width: 23rem;
        height: 22.2rem;
      }
    }
  }
}
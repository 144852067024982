@import '../../styles/variables.scss';

.faqs {
  margin-top: 2rem;

  &__uppercase {
    text-transform: uppercase;
  }

  .accordion {
    margin-top: 1rem;
  }

  &__section {
    margin-top: 3rem;

    .type-h3 {
      margin-bottom: 2.5rem;
    }

    table {
      width: 100%;
      border: 1px solid $color-white;
      border-radius: 1rem;
      margin-top: 1.5rem;
      overflow: hidden;

      tr {
        
        th {
          padding: 1rem; 
          background-color: $color-secondary;
          border-bottom: 1px solid $color-white;

          +th {
            border-left: 1px solid $color-white;
          }
        }

        td {
          padding: 1rem; 
  
          ul {
            padding-left: 2rem;
            margin: 0;
  
            li + li {
              margin-top: 0.5rem;
            }
          }

          + td {
            border-left: 1px solid $color-white;
          }
        }

        &:last-child {
          td { border-bottom: 0; }
        }
      }
    }
  }

  &__covid {
    padding-top: 2rem;
    font-size: 1.1rem;
    line-height: 1.5rem;
    text-align: left;
    display: inline-block;
  }

  @media screen and (min-width: $mob) {
    .accordion {
      margin-top: 2rem;
    }
  }
}
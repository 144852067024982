@import '../../styles/variables.scss';

.code-entry {
  $this: &;

  &__img {
    width: 16.5rem;
    height: 16.5rem;
    border-radius: 50%;
    border: 0.3rem solid $color-white;
    overflow: hidden;
    margin: 3rem auto 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  

  &__copy {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  &__inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top:2rem;
  }

  &__codes-wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &__codes {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .textfield + .textfield {
      margin-top: 2rem;
    }

    + #{$this}__codes {
      margin-top: 3rem;
    }

   &.code-entry__codes--error
   {
     .textfield__input:not(.temporal-valid):not(.textfield__input--select) {
        border: 0.3rem solid $color-alert;
      }
   }
    
  }

  &__ctas {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;

    .button + .button {
      margin-left: 1.5rem;
    }

    .link {
      width: 100%;
      margin-top: 2.5rem
    }
  }

  &__retailer {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .button {
    margin-top: 3rem!important;
    max-width: 36.4rem;
  }

  
  @media screen and (min-width: $tab) {
   &__img {
    width: 23.8rem;
    height: 23.8rem;
   }

   &__codes {
      flex-direction: row;
      justify-content: space-between;

      .form__fieldset {
        width: calc(100% / 3 - 15px);
      }

      .textfield + .textfield {
        margin-top: 0;
        margin-left: 2rem;
      }  
      
      + #{$this}__codes {
        margin-top: 0;
      }
    }
  }

  .layout-container--slim {
    max-width: 53rem;
  }
}
// Colours
$color-primary: #4B0983;
$color-secondary: #3B0073;
$color-tertiary: #6643A1;
$color-accent: #EBC373;
$color-alert: #FF4D4D;
$color-white: #fff;
$color-black: #000;

// Button
$button-font-weight: $font-family-bold-weight;
$button-border: .1rem solid $color-white;
$button-border-focus: .1rem solid $color-white;
$button-border-radius: 0;
$button-background: $color-secondary;
$button-padding: 1.3rem 3rem;

// Steps
$step-border: .3rem solid $color-white;
$step-border-radius: 50%;
$step-background: $color-white;
$step-padding: 1.2rem 12rem;
$step-width: 24rem;

// Forms
$textfield-border-width: .7rem;
$textfield-border: .3rem solid $color-white;
$textfield-border-error: .3rem solid $color-alert;
$textfield-border-focus: .3rem solid $color-accent;
$textfield-border-radius: 0;
$textfield-padding: 0.5rem 1.6rem;

// Thank you banner
$thanks-border-width: .3rem;
$thanks-border: .3rem solid $color-white;
$thanks-border-radius: 2rem;
@import '../../styles/variables.scss';

.header {
  $this: &;
  position: fixed;
  transition: transform .3s;
  padding: 1.5rem 2rem;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  background: $color-primary;
  border-bottom: 0.2rem solid $color-accent;

  @media screen and (min-width: $tab) {
    padding: 1.4rem 0 1.3rem;
    align-items: center;
    border-bottom: 0.3rem solid $color-accent;
  }

  &--hide {
    transform: translateY(-120%);
  }

  &--navopen {
    #{$this}__overlay {
      pointer-events: all;
      opacity: 1;
    }
  }

  &__group {
    color: $color-accent;
    text-transform: uppercase;
    padding: 1rem 0 2rem!important;
    font-weight: 600;
  }

  &__logo {
    display: flex;
    z-index: 2;

    img {
      display: block;
      width: auto;
      height: 3.4rem;
    }

    @media screen and (min-width: $tab) {
      img {
        height: 5.4rem;
      }
    }
  }

  &__nav {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
  }

  &__link {
    font-family: $font-family-base;
    font-weight: $font-family-semibold-weight;
    font-size: 2.8rem;
    line-height: 3.4rem;
    letter-spacing: 0.084rem;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
    cursor: pointer;

    &--md {
      font-size: 1.8rem;
      line-height: 3.1rem;
      letter-spacing: 0;
    }

    @media screen and (min-width: $tab) {
      font-size: 2.8rem;
      line-height: 3.1rem;
      letter-spacing: 0;

      &--md {
        font-size: 1.8rem;
        line-height: 3.1rem;
        letter-spacing: 0;
      }
    }
  }

  &__menuToggle {
    display: block;
    position: relative;
    top: 1.7rem;
    right: 2rem;
    z-index: 103;
    -webkit-user-select: none;
    user-select: none;

    @media screen and (min-width: $tab) {
      top: 2.5rem;
      right: 5rem;
    }

    input {
      display: block;
      width: 4rem;
      height: 3.2rem;
      position: absolute;
      top: -.7rem;
      left: -.5rem;
      cursor: pointer;
      opacity: 0; /* hide this */
      z-index: 2; /* and place it over the hamburger */
      -webkit-touch-callout: none;

      &:focus ~ .header__menuToggle__span {
        background: $color-accent;
      }
    }

    &__span {
      display: block;
      width: 3.2rem;
      height: .4rem;
      margin-bottom: .5rem;
      position: relative;
      background: $color-white;
      border-radius: .3rem;
      z-index: 1;
      transform-origin: .4rem 0px;
      transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                  background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                  opacity 0.55s ease;


      &:first-child {
        transform-origin: 0% 0%;
      }
  
      &:nth-last-child(2) {
        transform-origin: 0% 100%;
      }
    }

    input:checked ~ .header__menuToggle__span {
      opacity: 1;
      transform: rotate(45deg) translate(-2px, -1px);
      background: $color-white;
    }

    input:checked:focus ~ .header__menuToggle__span {
      background: $color-accent;
    }

    input:checked ~ .header__menuToggle__span:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    input:checked ~ .header__menuToggle__span:nth-last-child(2) {
      transform: rotate(-45deg) translate(1px, -1px);
    }

    input:checked ~ .header__menu {
      transform: none;
    }
  }

  &__menu {
    position: fixed;
    text-align: center;
    padding: 9rem 7rem;
    padding-top: 16rem;
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    background: $color-primary;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(100%, 0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;

    &__nav {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        margin-bottom: 5.5rem;
      }

      &__divider {
        border-top: 0.02rem solid $color-accent;
        padding-top: 2rem;
      }
    }

    &__footer {
      .link {
        text-transform: uppercase;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    @media screen and (min-width: $tab) {
      text-align: left;
      padding: 9.5rem 4rem 9rem;
      width: 27rem;
    }
  }

  &__overlay {
    transition: opacity .2s;
    pointer-events: none;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.5);
    z-index: 101;
  }

  &__visit {
    position: absolute;
    left: 1rem;
    top: 4rem;
    transform: translate(0,-50%);
    color: $color-accent;
    font-weight: 600;
    text-decoration: none;
    width: 11rem;
    text-align: center;
    width: 10.5rem;
    font-size: 1.4rem;
    line-height: 1.5rem;
    
    @media screen and (min-width: $tab) {
      top: 5.75rem;
      color: $color-primary;
      left: 2rem;
    }

    &:hover {
      text-decoration: underline;

      @media screen and (min-width: $tab) {
        color: $color-primary;
      }
    }
  }

}
